.modalContainer{
    top: 1vh;
    display: block;
    align-items: center;
    justify-content: center;
}

.formContainer{
    padding: 1%;
}

.modalContainer > .ant-modal-content > .ant-modal-body{
    height: 75vh;
    overflow-y: scroll;
}

.titleLabel,
.newswireUrlLabel,
.dateLabel,
.publisherLabel,
.authorLabel,
.senderLabel,
.mailboxLabel,
.categoriesLabel,
.tagLabel,
.featuredImageLabel,
.contentLabel{
    float: left;
    width: 15%;
    font-weight: bold;
    /* justify-content: center; */
    /* align-content: center; */
    /* border: 1px solid red; */
    padding: 4.5px 0px 4.5px 0px;
}

.titleInputContainer,
.newswireUrlInputContainer,
.dateInputContainer,
.publisherInputContainer,
.authorInputContainer,
.senderInputContainer,
.mailboxInputContainer,
.categoriesInputContainer,
.tagInputContainer,
.featuredImageInputContainer,
.contentInputContainer{
    width: 85%;
    float: left;
}

.ql-editor{
    height: 60vh;
    overflow-y: auto;
}

.featuredImageInputContainer,
.newswireUrlInputContainer{
    border: 1px solid #d9d9d9;
}
.featuredImageLabel,
.featuredImageInputContainer{
    padding: 0.5% 0 0.5% 0;
}

.titleInput-true,
.newsUrlInput-false,
.authorInput-true,
.senderInput-true,
.mailboxInput-true,
.categoriesInput-true,
.tagInput-true,
.contentInput-true{
    border: 1px solid red;
}

.button-modal-save-true,
.button-modal-save-true:hover{
	border-radius: 5px !important;
	background-color: #B2B2B2 !important;
	color: #ffffff !important;
	border-color: #B2B2B2 !important;
    cursor: not-allowed;
}

.button-modal-save-false{
	border-radius: 5px;
	background-color: #0F9859;
	color: #ffffff;
	border-color: #0F9859;
}

.button-modal-save-false:hover{
	background-color: #90D4B5;
	color: #ffffff;
	border-color: #90D4B5;
}

.button-modal-cancel{
	border-radius: 5px;
	color: #787D82;
	border-color: #787D82;
}

.button-modal-cancel:hover{
	background-color: #ffffff;
	color: tomato;
	border-color: tomato;
}

.button-message-stay{
	border-radius: 5px;
	background-color: #2C86DA;
	color: #FFFFFF;
	border-color: #FFFFFF;
}

.button-message-stay:hover{
	background-color: #ffffff;
	color: #2C86DA;
	border-color: #2C86DA;
}

.button-message-leave{
	border-radius: 5px;
	color: #787D82;
	border-color: #787D82;
}

.button-message-leave:hover{
	color: tomato;
	border-color: tomato;
}

.button-delete-source{
    border-color: tomato;
    color: tomato;
}

.button-delete-source:hover{
    border-color: white;
    background-color: tomato;
    color: white;
}

.button-data-refresh{
    border-radius: 8px;
    color:#2C86DA;
    border-color: #2C86DA;
}

.button-data-refresh:hover{
    border-radius: 8px;
    color:white;
    border-color: white;
    background-color: #2C86DA;
}

.rowContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 1%;
}
.colContainer{
    width: 31%;
    height: auto;
    object-fit: cover;
    padding: 1%;
    margin: 1%;
    background-color: white;
    border: 1px solid #757575;
    box-shadow: whitesmoke;
}
.colContainer-selected{
    width: 31%;
    height: auto;
    object-fit: cover;
    padding: 1%;
    margin: 1%;
    background-color: #D8FFE7;
    border: 1px solid #1890FF;
    box-shadow: #1890FF;
}
.featureImage{
    width: 100%; /* Adjust the width as needed */
    height: 30vh; /* Adjust the height as needed */
    object-fit: cover;
}
.featureImageSelected{
    margin-top: 5%;
    align-items: left;
    color: #3D9B70;
    font-weight: bold;
}
.ql-editor:focus{
    border: 1.11111px solid rgb(70, 171, 254);
    lighting-color: rgb(255, 255, 255);
}
.ql-editor{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}


/* .imagesModal {
    background-color: white;
    border: 1px solid #A8A8A8;
    height: auto;
}
.imagesModalTitle {
    padding: 0.5% 0.5% 0.5% 2%;
    box-shadow: 0px 2px #A8A8A8;
    margin-bottom: 2px;
}
.imagesModalContent{
    min-height: 200px;
    height: 200px;
    border-radius: 5px; 
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 5px;
    resize: vertical;
}
.imagesModalRowContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
}
.imagesModalColContent{
    width: 30%;
    height: auto;
    object-fit: cover;
    padding: 1%;
    margin: 1%;
    background-color: white;
    box-shadow: whitesmoke;
}
.imagesEditor{
    width: 100%;
    height: auto; 
    object-fit: cover;
} */

.site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
.edit-tag {
    user-select: none;
}
.tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
}



/* CSS for modal images */
#imagesModal {
    position: absolute;
    z-index: 10;
    background-color: white;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.5px solid grey;
    box-shadow: 0.5px 0.5px grey;
}
#imagesModalTitle {
    padding: 5px;
    cursor: move;
    background-color: #2686DA;
    color: white;
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px; 
    font-size: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.imagesModalCloseIcon{
    color: black;
}
.imagesModalCloseIcon:hover{
    color: white;
}
.imagesModalContent{
    min-height: 40vh;
    height: 40vh;
    min-width: 50vh;
    width: 50vh;
    border-bottom-left-radius: 5px; 
    border-bottom-right-radius: 5px; 
    overflow-y: auto;
    resize: both;
    padding: 0.5%;
}
.imagesModalRowContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 1%;
}
.imagesModalColContainer{
    width: 30%;
    height: auto;
    object-fit: cover;
    padding: 1%;
    margin: 1%;
    background-color: white;
    border: 1px solid #757575;
    /* box-shadow: whitesmoke; */
}
.imagesModalColContainer:hover{
    border: 1px solid #0F9859;
    box-shadow:
    0 0 0px 0px #B5B5B5,
    0 0 0px 0px #B5B5B5,
	0 0 10px 1.5px #B5B5B5,
	0 0 0px 0px #B5B5B5;
}
.images_in_modal{
    width: 100%; /* Adjust the width as needed */
    height: 15vh; /* Adjust the height as needed */
    object-fit: cover;
}




.manageTagsButton,
.manageCategoriesButton{
    display: flex;
    justify-content: center;
    color: #2686DA;
    margin: 0;
    width: 100%;
    border-radius: 0;
}
.manageTagsButton:hover,
.manageCategoriesButton:hover{
    background-color: #2686DA;
    color: white;
}


/* width */
.imagesModalContent::-webkit-scrollbar {
    width: 0.5vw;
}

/* Track */
.imagesModalContent::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
.imagesModalContent::-webkit-scrollbar-thumb {
    background: #888; 
}
  
/* Handle on hover */
.imagesModalContent::-webkit-scrollbar-thumb:hover {
    background: #555; 
}



@media (max-width:1000px) {

    .titleLabel,
    .newswireUrlLabel,
    .dateLabel,
    .publisherLabel,
    .authorLabel,
    .senderLabel,
    .mailboxLabel,
    .categoriesLabel,
    .tagLabel,
    .featuredImageLabel,
    .contentLabel{
        width: 100%;
        display: block;
        font-weight: bold;
    }

    .titleInputContainer{
        width: 100%;
        display: flex;
    }

    .newswireUrlInputContainer,
    .dateInputContainer,
    .publisherInputContainer,
    .authorInputContainer,
    .senderInputContainer,
    .mailboxInputContainer,
    .categoriesInputContainer,
    .tagInputContainer,
    .featuredImageInputContainer,
    .contentInputContainer
    {
        width: 100%;
        display: block;
    }
}