@import "./assets/css/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Lato");

.App {
	font-family: "Lato", sans-serif;
}

.App-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.page-header {
	background-color: #ffffff;
	box-sizing: border-box;
	height: 64px;
	left: 0px;
	top: 0px;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.46);
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 0px 25px;
	color: #04224c;
}

.header-nav {
	display: flex;
	flex-direction: row;
}

.body-content {
	/* flex-grow: 1;
	width: 80%;
	height: 100%;
	position: relative;
	padding: 50px 0px; */
	padding: 0% 5%;
	padding-bottom: 50px;
	text-align: center;
	min-height: 100vh;
	line-height: 120px;
}

.page-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #04224c;
	color: #ffffff;
	height: 45px;
	text-align: center;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
}

.table-row-light {
	background-color: #ffffff;
}

.table-row-dark {
	background-color: #f4f4f4;
}

.button-green {
	background-color: #3d9b70;
	color: #ffffff;
	border-color: #d9d9d9;
}

.button-green:hover,
.active {
	background-color: #ffffff;
	color: #3d9b70;
	border-color: #3d9b70;
}

.link-button {
	background-color: rgba(0, 0, 0, 0.29);
}

.link-button:hover {
	background-color: rgba(0, 0, 0, 0.29);
	color: #ffffff;
	border-color: rgba(0, 0, 0, 0.29);
}

.input-box {
	color: #000000;
}

.input-box:hover {
	border-color: rgba(0, 0, 0, 0.29);
}

.login-page,
.register-page,
.verify-page,
.reset-password-page,
.forget-password-page {
	/* font-family: "Oswald", sans-serif !important; */
	display: flex;
	flex-direction: column;
	padding: 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.form-box {
	/* font-family: "Oswald", sans-serif !important; */
	background-color: #ffffff;
	border: 1.5px solid #3d9b70;
	padding: 3%;
	border-radius: 10px;
	width: 30%;
}

.input-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.label-form {
	margin-right: 10px;
	font-weight: 500;
	text-align: left;
}

.input-form > input,
.input-form > p,
.input-form > .ant-select,
.input-form > .ant-input-password {
	width: 70% !important;
}

.input-form > p {
	margin: 0px;
	height: 32px;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1600px) {
	.input-form {
		flex-direction: column;
		align-items: flex-start;
	}

	.input-form > input,
	.input-form > p,
	.input-form > .ant-select,
	.input-form > .ant-input-password {
		width: 100% !important;
	}

	.label-form {
		margin-bottom: 10px;
	}
}

.background-image {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.3),
			rgba(255, 255, 255, 0.3)
		),
		url("assets/img/bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.logo {
	margin: 0px 0px 50px 0px;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item-selected a:hover {
	color: #3d9b70 !important;
}

.unauthorized-page {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	min-width: max-content;
}

.login-title {
	font-weight: 700;
	font-size: 26px;
	line-height: 39px;
	text-align: center;
	margin-bottom: 20px;
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.button-link {
	color: #4caf50;
	flex: 1 1 auto;
}

.button-link:hover,
.button-link:focus {
	color: #235b24;
}

.button-bar {
	height: 18px;
	border-left: 1px solid #000000;
}

.browse-file-button {
	margin: 0px 0.5vw 0px 0.5vw;
	color: #3d9b70;
	border: 1px solid #3d9b70;
}

.browse-file-button:hover {
	color: white;
	background-color: #3d9b70;
	border: 1px solid #3d9b70;
}

.submit-story-container {
	min-width: 200px;
	width: 60%;
	background-color: #d9d9d9;
	margin-top: 2%;
	padding: 2%;
	border-radius: 5px;
	box-shadow:
    0 0 0px 0px #B5B5B5,
    0 0 0px 0px #B5B5B5,
	0 0 10px 1.5px #B5B5B5,
	0 0 0px 0px #B5B5B5;
}

.submit-story-container-wrapper {
	display: flex;
	justify-content: center;
}

.paragraph-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.upload-file-container {
	border: 1px solid red;
	background-color: white;
	border-radius: 5px;
	margin: 5px;
	box-shadow: 0 0 0px 0px #b5b5b5, 0 0 0px 0px #b5b5b5, 0 0 2px 1.5px #b5b5b5,
		0 0 0px 0px #b5b5b5;
	/* display: grid; */
}

/* button-bar display:none when button is wrapped */
@media (max-width: 1204px) {
	.button-container {
		flex-direction: column;
	}
	.button-container > .button-link {
		flex: 0 0 auto;
	}
	.button-container > .button-bar {
		display: none;
	}
	.paragraph-wrapper {
		display: grid;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}
}

.ant-select-selection-placeholder {
	text-align: left !important;
}
