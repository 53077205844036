.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
}

.box {
	border: 1px solid #ccc; /* adds a border to the box */
	border-radius: 10px; /* adds rounded edges to the border */
	padding: 15px; /* adds padding inside the box */

	box-shadow: 2px 2px 5px #ccc;
	margin-bottom: 10px;
	/* box-shadow: 2px 2px 5px #ccc, 0 0 20px #ccc; */
}

.box-rss {
	border: 1px solid #ccc; /* adds a border to the box */
	border-radius: 10px; /* adds rounded edges to the border */
	padding: 15px; /* adds padding inside the box */

	margin-top: auto;
	box-shadow: 2px 2px 5px #ccc;
	/* box-shadow: 2px 2px 5px #ccc, 0 0 20px #ccc; */
}

.box1 {
	/*floats the data-box to the right */
	padding: 8px; /* adds padding to the data-box */
	/* border: 1px solid #ccc; adds a border to the data-box */
	border-radius: 10px; /* adds rounded edges to the border */

	margin-left: 180px;
}

.box2 {
	/*floats the data-box to the right */
	padding: 8px; /* adds padding to the data-box */
	/* border: 1px solid #ccc; adds a border to the data-box */
	border-radius: 10px; /* adds rounded edges to the border */

	margin-left: 180px;
	/* margin-top:55px; */
}

.dataLayout {
	margin-left: 20px; /* adds a margin to the left side of the span element */
	display: inline-block; /* make the span element as inline-block to make it respect the left margin */
}

.data-box {
	/*floats the data-box to the right */
	padding: 8px; /* adds padding to the data-box */
	/* border: 1px solid #ccc; adds a border to the data-box */
	border-radius: 10px; /* adds rounded edges to the border */

	margin-left: 200px;
}

.data-box1 {
	/*floats the data-box to the right */
	padding: 8px; /* adds padding to the data-box */

	border-radius: 10px; /* adds rounded edges to the border */

	margin-left: 200px;
}

.data-box2 {
	/*floats the data-box to the right */
	padding: 8px; /* adds padding to the data-box */
	border: 1px solid #ccc;
	border-radius: 10px; /* adds rounded edges to the border */
	margin-top: 0%;
}
.data-box3 {
	/* adds padding to the data-box */

	/* adds rounded edges to the border */

	margin-left: 189px;
}

.h3-test {
	display: inline;
}

.text {
	margin-left: 10px;
}

.table-format {
	padding: 100px;
}

.table-2 td {
	padding-top: 10px;
}

.table-3 td {
	padding-top: 10px;
}

.space-td {
	display: block;
}

.my-space {
	height: 0 !important;
}

.rounded-modal .ant-modal-content,
.rounded-modal .ant-modal-header {
	border-radius: 20px;
	/* display: none; */
}

.headermissing .ant-modal-header {
	display: none;
}

.rounded-modal {
	font-family: "Open Sans", sans-serif;
	color: #4a4a4a; /* dark gray */
	/* display: flex;
	justify-content: center;
	align-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin: 5px; */
}

.rounded-modal .title {
	font-size: 19px;
	line-height: 0;
	padding: 8px;
	font-weight: bold;
}

.rounded-modal {
	line-height: 1;
	padding: 5px;
	font-size: 15px;
}

.important-note {
	/* color: #9b9b9b; light gray */
	color: #ff7d21; /* light gray */
	font-size: 14px;
}

.custom-button:hover {
	border-color: red;
	color: red;
}
